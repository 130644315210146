import React, { useState, useEffect } from 'react';
import {Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {GetApp} from "@mui/icons-material";

const InstallPwaDialog: React.FC = () => {
    const [installPromptEvent, setInstallPromptEvent] = useState<Event | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: Event) => {
            event.preventDefault();
            setInstallPromptEvent(event);
            setOpen(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstall = () => {
        if (installPromptEvent) {
            (installPromptEvent as any).prompt();
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ mr: 2 }}> {/* Иконка приложения слева от заголовка */}
                        <img src="/logo192.png" alt="App Icon" width="40" height="40" />
                    </Avatar>
                    Установить приложение
                </Box>
            </DialogTitle>
            <DialogContent>
                <p>Установите это приложение на свой рабочий стол для быстрого доступа.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}  sx={{ mr: 1, borderRadius: 10, borderWidth: 2 }}>Отмена</Button>
                <Button onClick={handleInstall} color="primary"  sx={{ mr: 1, borderRadius: 10, borderColor:'primary',borderWidth: 2 }} >
                    <GetApp sx={{ mr: 1 }} /> {/* Иконка "Скачать" */}
                    Установить</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InstallPwaDialog;
