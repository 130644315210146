import {CircularProgress} from "@mui/material";
import React from "react";
import {Student} from "../../components/models/Student";

const CircularProgressBar: React.FC = () => {

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress/>
        </div>
    );
};
export default  CircularProgressBar;