// ApiService.ts
import axios, {AxiosError} from 'axios';
import apiConfig from '../../apiConfig';
import {jsonProperty, Serializable} from "ts-serializable";
import {Nullable} from "../../components/models/BaseTypes";
import {Student} from "../../components/models/Student";
import {acl, User, userMain} from "../../components/models/User";
import {reflow} from "@mui/material/transitions/utils";
import {Payment} from "../../components/models/Payment";
import {PayRequest} from "../../components/models/PayRequest";
import {Attendance} from "../../components/models/Attendance";
import {Case} from "../../components/models/case";
import {Schedule} from "../../components/models/Schedule";
import {Group} from "../../components/models/Group";
import {Lesson} from "../../components/models/Lesson";
import {Facility} from "../../components/models/Facility";



class ApiService {
    private static readonly USER_DATA_KEY = 'userData';
    private static readonly  Access_Token = "accessToken";
    private static readonly  ACL = "ACL";
    private  static  readonly  ACCESS_USERNAME="Access_Username";


    private static setUserData(userData: User): void {
        localStorage.setItem(ApiService.USER_DATA_KEY, JSON.stringify(userData));
    }

     static getUserData(): User | null {
        const storedData = localStorage.getItem(ApiService.USER_DATA_KEY);
        return storedData ? JSON.parse(storedData) : null;
    }

    private static removeUserData(): void {
        localStorage.removeItem(ApiService.USER_DATA_KEY);
    }

    private static setAccessToken(token: string): void {

        localStorage.setItem(ApiService.Access_Token, token);
       let userData = this.getUserData();
       userData!.token = token;
       this.setUserData(userData!);

    }

    private static setACL(acl:acl|null):void{
        localStorage.setItem(ApiService.ACL, JSON.stringify(acl));
    }

    public static getACL():acl|null{
      let storedData = localStorage.getItem(ApiService.ACL);
        return storedData ? JSON.parse(storedData) : null;
    }
    public  static  getPaymentACL():boolean
    {
      return  ( ApiService.getACL()?.table?.Payment?.read==="all" && ApiService.getACL()?.table?.Payment?.edit==="all"
       && ApiService.getACL()?.table?.Payment?.create==="yes");

    }

    private static getAccessToken(): string | null {
        const userData = ApiService.getUserData();
        return userData ? userData.token : null;
    }

    private static setUsername(username: string): void {
        localStorage.setItem(ApiService.ACCESS_USERNAME, username);
    }

    public static getUsername(): Nullable<string> {
      return   localStorage.getItem(ApiService.ACCESS_USERNAME);

    }

    private static removeUsername(): void {
        localStorage.setItem(ApiService.ACCESS_USERNAME, "");

    }

    private static async refreshToken(): Promise<void> {
        try {
            // Реализуйте обновление токена, если ваш сервер поддерживает такую функциональность
            // Сохраните новый токен в локальное хранилище
            // ApiService.setAccessToken(newToken);
        } catch (error) {
            console.error('Ошибка при обновлении токена', error);
            ApiService.removeAccessToken();
            // Обработка ошибки обновления токена
        }
    }

    private static removeAccessToken(): void {
        const userData = ApiService.getUserData();
        if (userData) {
            userData.token = null;
            ApiService.setUserData(userData);
        }
    }
    private static async requestWithToken<T>(method: 'get' | 'post'|'put', url: string, data?: any): Promise<T> {
        const token = ApiService.getAccessToken();
        const username =   this.getUsername();

        if (!token) {
            console.error('Токен отсутствует');
            throw new Error('Токен отсутствует');
        }
        try {

            const response = await axios.request({
                method,
                url,
                headers: {
                    'Espo-Authorization': `${btoa(`${username}:${token}`)}`,
                    'Content-Type': 'application/json',
                },
                data: data,
            });
            console.log(response);

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response?.status === 401) {

                    console.log('Получен ответ с кодом 401. Перенаправляем на страницу авторизации...');
                    ApiService.logout();
                    window.location.reload();
                }
            }
            console.error('Ошибка при выполнении запроса с токеном', error);
            throw error;
        }
    }

    static async login(username: string, password: string) {
        try {
            const response = await axios.get(apiConfig.USER, {
                headers: {
                    'Espo-Authorization': `${btoa(`${username}:${password}`)}`,
                },
            });

                let userDataMain: userMain = userMain.fromJSON(response.data);


                ApiService.setUserData(userDataMain.user!);
                this.setAccessToken(userDataMain?.token!)
            console.log(userDataMain?.acl);
                this.setACL(userDataMain?.acl);
                this.setUsername(userDataMain?.user?.userName!);
                return userDataMain.user!;

        } catch (error) {
            console.error('Ошибка при авторизации', error);
            throw error;
        }
    }

    static checkToken()
    {
        localStorage.getItem(this.Access_Token);
    }
    static logout() {
        ApiService.removeUserData();
        ApiService.removeAccessToken();
    }

    // Добавьте другие методы API по мере необходимости
    static async getStudents(): Promise< Student[] > {
        try {
          const resp =await this.requestWithToken<{total:number, list:Student[]}>("get", apiConfig.STUDENT);
          /*  const response = await axios.get<{ total: number; list: Student[] }>(
                apiConfig.STUDENT
            );*/

            return resp.list;
        } catch (error) {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }
       static async getStudentDetails(studentId: string): Promise<Student> {
        try {
            const response = await this.requestWithToken<Student>("get", apiConfig.STUDENTDETAIL+`/${studentId}`);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching student list:', error);
            throw error;
        }
        }

    static async getPayments(studentId: string | undefined): Promise<{ total: number; list: Payment[] }>{
        try {
            const resp =await this.requestWithToken<{total:number, list:Payment[]}>("get", apiConfig.PAYMENT);//TODO studentId
            /*  const response = await axios.get<{ total: number; list: Student[] }>(
                  apiConfig.STUDENT
              );*/

            return resp;
        } catch (error) {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }

    static async updatePayment(id: string, param: { paymentperiod: string; status: string; paymentType: string }) {
        try {
            const response = await this.requestWithToken<Payment>("put", apiConfig.PAYMENTDETAIL+`/${id}`,JSON.stringify(param));
            return response;
        }
        catch (error)
        {
            console.error('Error update payment:', error);
            throw error;
        }

    }

    static async getPayment(paymentId:string): Promise<Payment>{
        try {
            const response = await this.requestWithToken<Payment>("get", apiConfig.PAYMENTDETAIL+`/${paymentId}`);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }

    static  async  getPaymentByFilter(paymentStatuses:string[],studentId:string, paymentperiod:string, lastXDays:number=40):Promise<Payment[]>
    {
        const params = {
            where: [
                { type: 'in', attribute: 'status', value: paymentStatuses },
                { type: 'equals', attribute: 'paymentperiod', value: paymentperiod },
                { type: 'currentYear', attribute: 'createdAt' }
            ],
            offset:0,
            maxSize: 200,
            orderBy:"createdAt",
            order: 'desc'
        };
        if(!(studentId===null || studentId.length === 0)){
            params.where.push( { type: 'equals', attribute: 'studentId', value: studentId });
        }

        const searchParams = JSON.stringify(params).replace(' ','');
        console.log(searchParams);
        const response = await this.requestWithToken<{list:Payment[]}>("get", apiConfig.PAYMENT+`?searchParams=${searchParams}`);
        return response.list;
    }

    static async getPaymentByFilter2(
        paymentStatuses: string[],
        studentId: string,
        paymentperiods: string[],
        lastXDays: number = 100,
        currentPage: number = 1,
        pageSize: number = 200,
        searchTerm:string|null=""
    ): Promise<{ list: Payment[]; totalPages: number }> {
        const params: {
            where: { type: string; attribute?: string; value?: string[] | string | { type: string; attribute: string; value: string; }[] }[];
            offset: number;
            maxSize: number;
            orderBy: string;
            order: string;
        } = {
            where: [
                { type: 'in', attribute: 'status', value: paymentStatuses },
                { type: 'in', attribute: 'paymentperiod', value: paymentperiods },
                { type: 'currentYear', attribute: 'createdAt' }
            ],
            offset: (currentPage - 1) * pageSize,
            maxSize: pageSize,
            orderBy: 'modifiedAt',
            order: 'desc'
        };

        if (studentId !== null && studentId.length > 0) {
            params.where.push({ type: 'equals', attribute: 'studentId', value: studentId });
        }

        if (searchTerm !== null && searchTerm.length > 0) {
            params.where.push({
                type: 'or',
                value: [
                    { type: 'contains', attribute: 'clientName', value: searchTerm },
                    { type: 'contains', attribute: 'studentName', value: searchTerm }
                ]
            });
        }

            const searchParams = JSON.stringify(params).replace(' ', '');
        console.log(searchParams);
        const response = await this.requestWithToken<{ list: Payment[]; totalCount: number }>(
            'get',
            apiConfig.PAYMENT + `?searchParams=${searchParams}`
        );

        const totalPages = Math.ceil(response.totalCount / pageSize);
        return { list: response.list, totalPages };
    }
    static async createPayment(studentId:string, period:string, amount:string):Promise<Payment> {
        try {
            const data = JSON.stringify({ studentId: studentId, paymentperiod:period, summ:amount });
            const response = await this.requestWithToken<Payment>("post", apiConfig.PAYMENT,data);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }

    static async createPaymentRequest(paymentId:string, name:string):Promise<PayRequest> {
        try {
            const data = JSON.stringify({ paymentId: paymentId, name:name});
            const response = await this.requestWithToken<PayRequest>("post", apiConfig.PAYMENTREQUEST,data);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }

    static async getAttendance(studentId:string, minDate:string): Promise<Attendance[]>{
        try {
            const response = await this.requestWithToken<{list:Attendance[]}>("get", apiConfig.ATTENDANCECLIENT+`/${studentId}`+`?dateMin=${minDate}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async postAttendanceComment(studentId:string, comment:string, attendanceId:string, isVisit:boolean ): Promise<Attendance>{
        try {
            const data = JSON.stringify({ id: attendanceId, commentClient:comment, isAttendance:isVisit});

            const response = await this.requestWithToken<Attendance>("post", apiConfig.ATTENDANCECLIENTSETCOMMENT+`/${studentId}`,data);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }


    static async getCases(): Promise<Case[]>{
        try {
            const response = await this.requestWithToken<{list:Case[]}>("get", apiConfig.CASES);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static  async  updateCase(id:string, param: {  status: string; ansver: string })
    {
        try {
            const response = await this.requestWithToken<Case>("put", apiConfig.CASES+`/${id}`,JSON.stringify(param));
            return response;
        }
        catch (error)
        {
            console.error('Error update payment:', error);
            throw error;
        }
    }

    static async getCaseDetail(id:string): Promise<Case>{
        try {
            const response = await this.requestWithToken<Case>("get", apiConfig.CASES+`/${id}`);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async createCase(subject:string, type:string, question:string):Promise<Case> {
        try {
            const data = JSON.stringify({ subject: subject, type:type,description:question});
            const response = await this.requestWithToken<Case>("post", apiConfig.CASES,data);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching student list:', error);
            throw error;
        }
    }


    static async changePassword(oldPassword: string, newPassword1: string, newPassword2: string) {
        try {
            const data = JSON.stringify({ currentPassword: oldPassword, password:newPassword1,passwordConfirm:newPassword2});
            const response = await this.requestWithToken<boolean>("put", apiConfig.CHANGEPASSWORD,data);
            return response;
        }
        catch (error)
        {
            console.error('Error change password:', error);
            throw error;
        }
    }

    static async postAgreement() {
        try {
            let user=this.getUserData();
            const data = JSON.stringify({ isAgreement:true, userId:user?.id});
            const response = await this.requestWithToken<boolean>("post", apiConfig.SETUSERAGREEMENT,data);
            user!.isAgreement = true;
            this.setUserData(user!);
            return response;
        }
        catch (error)
        {
            console.error('Error change Agreement:', error);
            throw error;
        }
    }

    static async getSchedule(studentId:string): Promise<Schedule[]>{
        try {
            const response = await this.requestWithToken<{list:Schedule[]}>("get", apiConfig.SHEDULE+`/${studentId}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getGroups(facilityId:string|null = null): Promise<Group[]>{
        try {
            const params: {
                where: { type: string; attribute: string; value?: string[]|string  }[];
                offset?: number;
                maxSize: number;
                orderBy: string;
                order: string;
            } = {
                where:[],
                maxSize: 200,
                orderBy: 'name',
                order: 'asc'
            };


                if (!(facilityId === null || facilityId.length === 0)) {
                    params.where.push({ type: 'equals', attribute: 'facilityId', value: facilityId });
                }

            const searchParams = JSON.stringify(params).replace(' ', '');
            const response = await this.requestWithToken<{list:Group[]}>("get", apiConfig.GROUP+ `?searchParams=${searchParams}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getGroupSchedules(groupId:string): Promise<Schedule[]>{
        try {
            const response = await this.requestWithToken<{list:Schedule[]}>("get", apiConfig.GROUP+`/${groupId}/scheduleElements`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }
    static async getGroup(groupId:string): Promise<Group>{
        try {
            const response = await this.requestWithToken<Group>("get", apiConfig.GROUP+`/${groupId}`);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getFacilityes(): Promise<Facility[]>{
        try {
            const response = await this.requestWithToken<{list:Facility[]}>("get", apiConfig.FACILITY);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Facility list:', error);
            throw error;
        }
    }

    static async getGroupStudents(groupId:string): Promise<Student[]>{
        try {
            const response = await this.requestWithToken<{list:Student[]}>("get", apiConfig.GROUP+`/${groupId}/students`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getStudentVisits(studentId: string, minDate:number): Promise<Attendance[]> {
        try {

            const params = {
                select: ['id', 'name', 'lesontime', 'studentId',"commentClient","isVisit"],
                where: [
                    { type: 'lastXDays', attribute: 'lesontime', value: minDate },
                    { type: 'equals', attribute: 'studentId', value: studentId }
                ],
                maxSize: 30,
                orderBy: 'lesontime',
                order: 'desc'
            };

            const searchParams = JSON.stringify(params).replace(' ','');
            const response = await this.requestWithToken<{list:Attendance[]}>("get", apiConfig.ATTENDANCES+`?searchParams=${searchParams}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async setStudentVisit(visitId: string, visit:Attendance) {
        try {
            console.log( JSON.stringify(visit));
            const response = await this.requestWithToken<Attendance>("put", apiConfig.ATTENDANCES+`/${visitId}`, JSON.stringify(visit));
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getLessons(minDate:string,maxDate:string):Promise<Lesson[]> {
        try {

            const params = {
                select: ['id', 'name', 'lessontime', 'endtime',"groupId","groupName"],
                where: [
                    { type: 'greaterThanOrEquals', attribute: 'lessontime', value: minDate },
                    { type: 'lessThan', attribute: 'lessontime', value: maxDate },
                    { type: 'isTrue', attribute: 'isActiv' }
                ],
                maxSize: 200,
                orderBy: 'lessontime',
                order: 'desc'
            };

            const searchParams = JSON.stringify(params).replace(' ','');
            const response = await this.requestWithToken<{list:Lesson[]}>("get", apiConfig.LESSONS+`?searchParams=${searchParams}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }

    static async getLesson(id:string):Promise<Lesson> {
        try {

            const response = await this.requestWithToken<Lesson>("get", apiConfig.LESSONS+`/${id}`);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Lesson list:', error);
            throw error;
        }
    }

    static async getLessonsForWeek(map: string[], startDate: string, endDate: string):Promise<Lesson[]> {

        const params = {
            where: [
                { type: 'in', attribute: 'groupId', value: map },
                { type: 'between', attribute: 'lessontime', value: [startDate, endDate] },
                { type: 'currentYear', attribute: 'createdAt' },
                { type: 'isTrue', attribute: 'isActiv' }

            ],
            offset:0,
            maxSize: 200,
            orderBy:"lessontime",
            order: 'asc'
        };

        const searchParams = JSON.stringify(params).replace(' ','');
        const response = await this.requestWithToken<{list:Lesson[]}>("get", apiConfig.LESSONS+`?searchParams=${searchParams}`);
        return response.list;


    }

    static async createLesson(GroupId:string, lessontime:string, endLessonTime:string):Promise<Lesson> {
        try {
            const data = JSON.stringify({ groupId: GroupId, lessontime:lessontime,endtime:endLessonTime});
            console.log(data);
            const response = await this.requestWithToken<Lesson>("post", apiConfig.LESSONS,data);
            return response;
        }
        catch (error)
        {
            console.error('Error fetching Lesson list:', error);
            throw error;
        }
    }
    static async getLessonVisits(lessonId:string): Promise<Attendance[]> {
        try {
            const params = {
                offset:0,
                maxSize: 200,
                orderBy:"studentName",
                order: 'asc'
            };

            const searchParams = JSON.stringify(params).replace(' ','');

            const response = await this.requestWithToken<{list:Attendance[]}>("get", apiConfig.LESSONS+`/${lessonId}/attendances`+`?searchParams=${searchParams}`);
            return response.list;
        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }


   /* static async getStudentImage(imageId:string): Promise<Blob>{
        try {
            const token = ApiService.getAccessToken();
            const username =   this.getUsername();




         let response =  await axios.get(`https://crm.ateshstudio.ru/?entryPoint=image&size=medium&id=${imageId}&portalId=651a7418c3e4d46f2`,
               { responseType: 'blob',
                   headers: {
                       'Espo-Authorization': `${btoa(`${username}:${token}`)}`,
                       'Content-Type': 'application/json',
                   }
               });
            console.log(response.status);
            return response.data;

        }
        catch (error)
        {
            console.error('Error fetching Attendance list:', error);
            throw error;
        }
    }*/

}

export default ApiService;
