// CommonHeader.tsx

import React from 'react';
import {AppBar, Toolbar, IconButton, Typography, Button, BottomNavigationAction} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarIcon from '@mui/icons-material/CalendarMonth';

import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import ApiService from "../api/ApiService";

interface CommonHeaderProps {
    title: string;
    showBackButton?: boolean;
    onBackButtonClick?: () => void;
    showLogoutButton?: boolean;
    onLogoutButtonClick?: () => void;
    showCalendarButton?: boolean;
    onCalendarButtonClick?: () => void;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ title,showBackButton, showLogoutButton,showCalendarButton,onCalendarButtonClick }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCalendar = () => {

    };

    const handleLogout = () => {
        // Добавьте здесь логику для разлогина
        // Например, очистка токена и перенаправление на страницу авторизации
       // localStorage.removeItem('accessToken');
        console.log("logout");
        ApiService.logout();
        navigate('/');
        window.location.reload();

    };

    return <AppBar position="static" background-color="primary" >
        <Toolbar>
            {showBackButton ? (<IconButton edge="start" color="inherit" onClick={handleGoBack} sx={{ mr: 2 }}>
                <ArrowBackIcon />
            </IconButton>):(<></>)
            }
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {title}
            </Typography>
            {showLogoutButton ? (
            <Button color="inherit" onClick={handleLogout} startIcon={<LogoutIcon />}>
            </Button>):(<></>)}


            {(ApiService.getACL()?.table?.Payment?.read==="all" && showCalendarButton) ? (<IconButton edge="start" color="inherit" onClick={onCalendarButtonClick} sx={{ mr: 2 }}>
                <CalendarIcon />
            </IconButton>):(<></>)
            }

        </Toolbar>
    </AppBar>;
};

export default CommonHeader;
