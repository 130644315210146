import {Serializable, jsonProperty} from "ts-serializable";
import type { Nullable } from './BaseTypes';
import moment from "moment";
export class Payment
{
    @jsonProperty(String, null) id: Nullable<string> = null;
    @jsonProperty(String, null) name: Nullable<string> = null;
    @jsonProperty(Boolean, null) deleted: boolean = false
    @jsonProperty(String, null)description: Nullable<string> = null;
    @jsonProperty(String, null)createdAt: Nullable<string> = null;
    @jsonProperty(String, null)modifiedAt: Nullable<string> = null;
    @jsonProperty(Number, null) summ: Nullable<Number> = 0.0;
    @jsonProperty(String, null) dateFrom: Nullable<string> = null;
    @jsonProperty(String, null)dateTo: Nullable<string> = null;
    @jsonProperty(String, null) paymentperiod: Nullable<string> = null;
    @jsonProperty(String, null) status: Nullable<string> = null;
    @jsonProperty(String, null) paymentType: Nullable<string> = null;
    @jsonProperty(String, null) summCurrency: Nullable<string> = null;
    @jsonProperty(String, null) createdById: Nullable<string> = null;
    @jsonProperty(String, null) createdByName: Nullable<string> = null;
    @jsonProperty(String, null) modifiedById: Nullable<string> = null;
    @jsonProperty(String, null)modifiedByName: Nullable<string> = null;
    @jsonProperty(String, null) assignedUserId: Nullable<string> = null;
    @jsonProperty(String, null)assignedUserName: Nullable<string> = null;
    @jsonProperty(Number, null)summConverted: Nullable<number> = null;
    @jsonProperty(String, null) userId: Nullable<string> = null;
    @jsonProperty(String, null) userName: Nullable<string> = null;
    @jsonProperty(String, null) clientId: Nullable<string> = null;
    @jsonProperty(String, null) clientName: Nullable<string> = null;
    @jsonProperty(String, null) contactId: Nullable<string> = null;
    @jsonProperty(String, null) contactName: Nullable<string> = null;
    @jsonProperty(String, null) studentId: Nullable<string> = null;
    @jsonProperty(String, null)studentName: Nullable<string> = null;
}

export enum PaymentStatus{
    created="created",
    canceled="canceled",
    waiting_for_capture="waiting_for_capture",
    succeeded="succeeded",
    pending="pending"
}
export class PaymentHelper{

    static paymentTypes = {
        online: 'Онлайн',
        cash: 'Наличные',
        card: 'Карта'

    };

    static months = {
        Jan: 'Январь/1',
        Feb: 'Февраль/2',
        Mart: 'Март/3',
        Apr: 'Апрель/4',
        May: 'Май/5',
        Jin: 'Июнь/6',
        Jil: 'Июль/7',
        Avgust: 'Август/8',
        Sep: 'Сентябрь/9',
        Okt: 'Октябрь/10',
        Nov: 'Ноябрь/11',
        Dec: 'Декабрь/12',
        none: 'Другое'
    };

    static status = {
        succeeded: 'Оплачено',
        created: 'Создан',
        error: 'Ошибка',
        canceled: 'Отменен',
        none: 'Другое'
    };

    static getMounthCurrentKey=()=>
    {
        const currentMonthIndex = new Date().getMonth(); // Получаем индекс текущего месяца (0-11)
        const monthKeys = Object.keys(PaymentHelper.months); // Получаем ключи месяцев
        const currentMonthKey = monthKeys[currentMonthIndex]; // Получаем ключ текущего месяца
//        const defaultMonthValue = months[currentMonthKey as keyof typeof months]; // Получаем значение по умолчанию из
        return currentMonthKey;
    };
    static getStatusName = (status: string) => {
        switch (status) {
            case 'succeeded':
                return 'Оплачено'; // Зеленый
            case 'wait':
            case 'created':
                return 'Создан'; // Желтый
            case 'error':
            case 'canceled':
                return 'Отменен'; // Красный
            default:
                return 'Ожидает'; // Серый (по умолчанию)
        }
    };

    static getStatusColor = (status: string) => {
        switch (status) {
            case 'succeeded':
                return '#4caf50'; // Зеленый
            case 'wait':
            case 'created':
                return '#ffeb3b'; // Желтый
            case 'error':
            case 'canceled':
                return '#f44336'; // Красный
            default:
                return '#e0e0e0'; // Серый (по умолчанию)
        }
    };

    static getMontName=(name:string)=>
    {
        switch (name)
        {
            case  'Jan':return  'Январь/1';
            case   "Feb":return 'Февраль/2';
            case  "Mart":return 'Март/3';
            case  "Apr": return 'Апрель/4';
            case   "May": return 'Май/5';
            case  "Jin": return 'Июнь/6';
            case   "Jil": return 'Июль/7';
            case   "Avgust": return 'Август/8';
            case   "Sep": return 'Сентябрь/9';
            case   "Okt": return 'Октябрь/10';
            case   "Nov": return 'Ноябрь/11';
            case    "Dec": return 'Декабрь/12';
              default: return'Другое';
        }
    };

    static getMounthKeyByName = (name: string): keyof typeof PaymentHelper.months | undefined => {
        const keys = Object.keys(PaymentHelper.months) as Array<keyof typeof PaymentHelper.months>;
        return keys.find(key => PaymentHelper.months[key] === name);
    };

    static getStatusKeyByName = (name: string): keyof typeof PaymentHelper.status | undefined => {
        const keys = Object.keys(PaymentHelper.status) as Array<keyof typeof PaymentHelper.status>;
        return keys.find(key => PaymentHelper.status[key] === name);
    };








}