import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Typography,
    Chip,
    Box,
    TextField,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Collapse, Divider, FormControlLabel, Checkbox, IconButton
} from '@mui/material';
import ApiService from "../../../base/api/ApiService";
import CommonHeader from "../../../base/views/CommonHeader";
import helper from "../../../components/Helper";
import Helper from "../../../components/Helper";
import CircularProgressBar from "../../../base/views/CircularProgressBar";
import {Attendance} from "../../../components/models/Attendance";
import moment from "moment/moment";
import {Lesson} from "../../../components/models/Lesson";
import VisitsDisplay from "../../../components/VisitDisplay";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const LessonDetailPage: React.FC = () => {
    const { lessonId } = useParams<{ lessonId: string }>();
    const [lessonInfo, setLessonInfo] = useState<Lesson>();
    const [attendances, setAttendances] = useState<Attendance[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [expandedItemId, setExpandedItemId] = useState<string | null>(null);
    const navigate = useNavigate();



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Получаем информацию о занятии
                const lessonData = await ApiService.getLesson(lessonId as string);
                setLessonInfo(lessonData);

                // Получаем присутствие за последние N дней
                const attendanceData = await ApiService.getLessonVisits(lessonId as string);
              //  attendanceData.sort((a, b) => a.name!.localeCompare(b.name!))

                setAttendances(attendanceData);

                setIsLoading(false);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchData();
    }, [lessonId]);

    const handleAttendanceChange = async (studentId: string, isPresent: boolean) => {
        try {
            // Отправляем запрос на изменение присутствия
           // await ApiService.updateAttendance(lessonId, studentId, isPresent);
        } catch (error) {
            console.error('Ошибка при обновлении присутствия:', error);
        }
    };
    const setIsVisit = (visit: Attendance, isVisit: boolean) => {
        setAttendances(prevVisits => {
            const updatedVisits = prevVisits.map(prevVisit => {
                if (prevVisit.id === visit.id) {
                    return { ...prevVisit, isVisit };
                } else {
                    return prevVisit;
                }
            });
            return updatedVisits;
        });
    };
    const handleCommentSubmit = async (visit: Attendance) => {
        try {
            const attendance = await  ApiService.setStudentVisit(visit.id as string,visit);
            // Здесь вызовите вашу функцию API для отправки комментария
            console.log('Отправка комментария для посещения:', visit);
        } catch (error) {
            console.error('Ошибка при отправке комментария:', error);
        }
    };


    const handleGoToStudentClick=(studentId: string | null) => {
        navigate(`/group/student/${studentId}`);    };
    const handleListItemClick = (visitId: string | null) => {
        if (visitId === expandedItemId) {
            setExpandedItemId(null);
        } else {
            setExpandedItemId(visitId);

        }
    };

    //TODO
    const getStatusLabel = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return 'Расписание';
        } else {
            return visit.isVisit ? 'Присутствие' : 'Отсутствие';
        }
    };

    ///TODO refractor
    const getStatusColor = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return '#e0e0e0'; // Серый
        } else {
            return visit.isVisit ? '#4caf50' : '#f44336'; // Зеленый или красный
        }
    };
    const handleIsVisitChangeSubmit = (visit: Attendance, isVisit:boolean) => {
        //onVisitSubmit(visit, isVisit);
        setIsVisit(visit, isVisit);
    };
    const handleCopyLesson = () => {
        navigate('/lesson/create/'+lessonId)
       // navigate('/CreateLessonPage', { state: { lessonInfo } });
    };

    if (isLoading) {
        return <CircularProgressBar />;
    }

    return (
        <div>
            <CommonHeader title={"Занятие"} showBackButton={true}/>
            <div style={{marginLeft:'5px', marginRight:'5px', marginTop:'5px'}}>
            <Typography variant="h4">{lessonInfo?.groupName}</Typography>
            <Typography variant="h6">{Helper.formatTimeWithTimeZone(lessonInfo?.lessontime as string)}</Typography>
             <Button onClick={handleCopyLesson}>Копировать</Button>
            </div>
            <List>
                {attendances.map((visit) => (
                    <div key={visit.id}>
                        <ListItem button onClick={() => handleListItemClick(visit.id)}
                                  secondaryAction={
                                      visit.id === expandedItemId && (
                                          <IconButton
                                              edge="end"
                                              onClick={() => handleGoToStudentClick(visit.studentId)} // Замените на ваш обработчик
                                          >
                                              <ArrowForwardIcon />
                                          </IconButton>
                                      )
                                  }
                        >
                            <ListItemText
                                primary={visit.studentName}
                                secondary={
                                    <Chip
                                        label={getStatusLabel(visit)}
                                        style={{ backgroundColor: getStatusColor(visit), color: 'white' }}
                                    />
                                }
                            />
                        </ListItem>
                        <Collapse in={visit.id === expandedItemId} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box p={2}>
                                <VisitsDisplay studentId={visit.studentId as string} />
                                <FormControlLabel
                                    control={<Checkbox checked={visit.isVisit as boolean} onChange={(e) =>handleIsVisitChangeSubmit(visit,e.target.checked ) } />}
                                    label="Будет на занятии"
                                />
                                <TextField
                                    label="Комментарий"
                                    variant="outlined"
                                    fullWidth
                                    value={visit.commentClient}
                                    onChange={(e) => setComment(visit.commentClient=e.target.value)}
                                    multiline
                                    rows={4}
                                    sx={{ marginBottom: '3px' }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCommentSubmit(visit)}
                                >
                                    Отправить
                                </Button>
                            </Box>
                        </Collapse>
                        <Divider />
                    </div>
                ))}
            </List>

        </div>
    );
};

export default LessonDetailPage;
