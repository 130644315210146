// CreateCasePage.tsx
import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Button, TextField, Typography, MenuItem, CircularProgress, Box} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import CommonHeader from "../../base/views/CommonHeader";

const CreateCasePage: React.FC = () => {
    const navigate=useNavigate();
    const [subject, setSubject] = useState('');
    const [type, setType] = useState('');
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);

    const types = {
        Question: 'Вопрос',
        Incident: 'Инцидент',
        Problem: 'Проблема',
        offer: 'Предложение'
    };
    const handleCreateCase = async () => {
        try {
            setLoading(true);
          const newcase =  await ApiService.createCase( subject, type, question );
            navigate(`/cases`);
        } catch (error) {
            console.error('Ошибка создания обращения:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as string); // Установка значения paymentPeriod равным value
    };

    return (
        <div>
            <CommonHeader title="Создать обращение" />
            <Box margin={1.5} >
            <TextField
                label="Тема"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                select
                label="Тип"
                value={type}
               // onChange={(e) => setType(e.target.value)}
                onChange={handleChangeType}
                variant="outlined"
                fullWidth
                margin="normal"
            >
                {Object.entries(types).map(([key, value]) => (
                    <MenuItem key={key} value={key} defaultValue={3} data-index={key} >
                        {value}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Вопрос"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                margin="normal"
            />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (


                <Box sx={{ position: 'fixed', bottom: 70, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                <Button variant="contained" color="primary"   onClick={() => handleCreateCase()}>
                Создать обращение
                </Button>
                </Box>
            )}
            </Box>
        </div>
    );
};

export default CreateCasePage;
