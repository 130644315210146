import {Serializable, jsonProperty} from "ts-serializable";
import type { Nullable } from './BaseTypes';
export class Case
{
    @jsonProperty(String, null) id: Nullable<string> = null;
    @jsonProperty(String, null) name: Nullable<string> = null;
    @jsonProperty(Boolean, null) deleted: boolean = false
    @jsonProperty(String, null)description: Nullable<string> = null;
    @jsonProperty(String, null)createdAt: Nullable<string> = null;
    @jsonProperty(String, null)modifiedAt: Nullable<string> = null;
    @jsonProperty(String, null) status: Nullable<string> = null;
    @jsonProperty(String, null) createdById: Nullable<string> = null;
    @jsonProperty(String, null) createdByName: Nullable<string> = null;
    @jsonProperty(String, null) modifiedById: Nullable<string> = null;
    @jsonProperty(String, null)modifiedByName: Nullable<string> = null;
    @jsonProperty(String, null) assignedUserId: Nullable<string> = null;
    @jsonProperty(String, null)assignedUserName: Nullable<string> = null;
    @jsonProperty(String, null) contactId: Nullable<string> = null;
    @jsonProperty(String, null) contactName: Nullable<string> = null;
    @jsonProperty(String, null)  type: Nullable<string> = null;
    @jsonProperty(String, null)  subject: Nullable<string> = null;

    @jsonProperty(String, null) ansver: Nullable<string> = null;
    @jsonProperty(String, null) inboundEmailId: Nullable<string> = null;
    @jsonProperty(String, null) inboundEmailName: Nullable<string> = null;
}

export class  CaseHelper{
     static getTypeName=(type:string)=>
     {
         switch (type)
         {

             case  "Question": return "Вопрос";
             case     "Incident":return 'Инцидент';
             case  "Problem":return 'Проблема';
             case  "offer":return "Предложение";
             default:
                 return 'Другое';
         }
     };

    static status = {
        New: 'Новая',
        Assigned: 'На рассмотрении',
        Pending: 'На рассмотрении',
        Closed:'Закрыт',
        Rejected:'Отклонено',
        Duplicate:'Дубликат',
        none: 'Другое'
    };

    static getStatusColor = (status: string) => {
        switch (status) {
            case 'New':
                return '#FF0000';
            case 'Pending':
                return '#f3c504';
            case 'InProgress':
                return '#FF0000';
            case 'Closed':
                return '#0a6203';
            case 'Rejected':
                return '#FF0000';
            case 'Duplicate':
                return '#FF0000';
            default:
                return 'primary';
        }
    };


}



