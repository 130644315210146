import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, LinearProgress, MenuItem, TextField} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import {Student} from "../../components/models/Student";
import CommonHeader from "../../base/views/CommonHeader";
import {Payment, PaymentHelper, PaymentStatus} from "../../components/models/Payment";
import PaymentDialog from "./RepeatedPaymentDialog";
import CircularProgressBar from "../../base/views/CircularProgressBar";


const CreatePaymentPage : React.FC = () => {

    const { studentId } = useParams<{ studentId: string }>(); // Явное указание типа для studentId
    const [studentData, setStudentData] = useState<Student | null>(null);
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [paymentPeriod, setPaymentPeriod] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Состояние для индикации загрузки
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [lastSuccesPayment, setLastSuccesPayment]=useState<Payment|null>(null)

    useEffect(() => {

      //  console.log('fff'+getMounthCurrentKey());
        setPaymentPeriod(PaymentHelper.getMounthCurrentKey() as string);
        const fetchStudentData = async () => {
            try {
                // Выполните запрос к API, чтобы получить данные студента
                const student = await ApiService.getStudentDetails(studentId as string);
                setStudentData(student);
                setAmount(student.monthlyFee?.toString() || "0");
                setIsLoading(false);

            } catch (error) {
                console.error('Ошибка при загрузке данных студента:', error);
                setIsLoading(false);

            }
        };

        fetchStudentData();
    }, [studentId]);


    const checkPreviousPayment=async ()=>
    {
        try{
            setIsLoading(true);
            const  paymentInPeriodIsComplit =
                await  ApiService.getPaymentByFilter([PaymentStatus.succeeded],
                    studentId as string, paymentPeriod, 50);
            if(paymentInPeriodIsComplit!=null && paymentInPeriodIsComplit.length>0)
            {
                setLastSuccesPayment(paymentInPeriodIsComplit[0]);
                setShowPaymentDialog(true);
            }
            else {
               await handlePayment();
            }
        }
        catch (error)
        {
            setIsLoading(false);

        }
    }
    const handlePayment = async () => {
        try {
            setIsLoading(true);

            let payment;
            const  paymentList = await  ApiService.getPaymentByFilter([PaymentStatus.created,PaymentStatus.canceled],studentId as string, paymentPeriod, 50);
            if(paymentList!= null && paymentList.length>0) payment = paymentList[0];
            else {
                payment = await ApiService.createPayment(studentId as string, paymentPeriod, amount as string);
            }
            console.log("PaymentId");
            console.log(payment.id);
            if(payment!=null)
            {
                const payRequest = await  ApiService.createPaymentRequest(payment?.id as string ,'')
                if(payRequest!=null && payRequest.paymentURL!=null)
                {
                    navigate(`/students/${studentId}/payments`);
                   // window.open(payRequest.paymentURL as string);
                    setTimeout(() => {
                        window.open(payRequest.paymentURL as string);
                    })
                }
            }
            setIsLoading(false);
         //   navigate(confirmationData.paymentURL);
        } catch (error) {
            console.error('Payment Error:', error);
            setIsLoading(false);
        }
    };

    if (!studentData) {

        return <div><LinearProgress /></div>;
    }

    const handleChangePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPaymentPeriod(event.target.value as string); // Установка значения paymentPeriod равным value
    };

   const handleDialogClose=()=>
   {
       setShowPaymentDialog(false);
       setIsLoading(false);


   };



    return (
        <div>
        <CommonHeader title="Оплата" showBackButton={true} />
            <Box sx={{ mt: 4, width: '100%' }}>
                {isLoading && <CircularProgressBar />}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2 }}>
                    <TextField
                        label="Сумма"
                        type="number"
                        value={amount}
                        //onChange={e => setAmount(e.target.value)}
                        variant="outlined"
                        sx={{width:'80%'}}
                    />
                    <TextField
                        select
                        label="Период оплаты"
                        value={paymentPeriod}
                        onChange={handleChangePeriod}
                        variant="outlined"
                        sx={{width:'80%'}}
                    >
                        {Object.entries(PaymentHelper.months).map(([key, value]) => (
                            <MenuItem key={key} value={key} defaultValue={3} data-index={key} >
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button variant="contained" onClick={checkPreviousPayment} disabled={isLoading}>
                        Оплатить
                    </Button>
                </Box>
            </Box>
            {showPaymentDialog && <PaymentDialog payment={lastSuccesPayment} onClose={handleDialogClose} onYes={handlePayment} />}
        </div>
    );
};

export default CreatePaymentPage;