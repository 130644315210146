import { createTheme } from '@mui/material/styles';

const themeAtesh = createTheme({
    palette: {
        primary: {
            main: '#fd5508', // ваш новый основной цвет
        },
        secondary: {
            main: '#00ff00', // ваш второстепенный цвет
        },
        // Дополнительные настройки цветов...
    },

});

export default themeAtesh;
