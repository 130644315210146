// CasesListPage.tsx
import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {List, ListItem, ListItemText, Chip, Typography, CircularProgress, Box, Button} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import {Case,CaseHelper} from "../../components/models/case";

import {Nullable} from "../../components/models/BaseTypes";
import CommonHeader from "../../base/views/CommonHeader";


const CasesListPage: React.FC = () => {
    const [cases, setCases] = useState<Case[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCases = async () => {
            try {
                const response = await ApiService.getCases();
                setCases(response);
            } catch (error) {
                console.error('Ошибка получения списка обращений:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, []);

    const handleCreateCase = () => {
        navigate(`/createcase`);
    };
    const getStatusColor = (status: Nullable<string>) => {
        switch (status) {
            case 'New':
                return 'default';
            case 'Completed':
                return 'success';
            case 'InProgress':
                return 'warning';
            default:
                return 'default';
        }
    };


    return (
        <div>
            <CommonHeader title="Обращения" />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <List>
                    {cases.map((caseItem) => (
                        <ListItem key={caseItem.id} button component={Link} to={`/cases/${caseItem.id}`}>
                            <ListItemText
                                primary={CaseHelper.getTypeName(caseItem?.type ?? "")}
                                secondary={`Тема: ${caseItem.subject} - Дата: ${caseItem.createdAt}`}
                            />
                            <Chip label={caseItem.status} color={getStatusColor(caseItem.status)} />
                        </ListItem>
                    ))}
                </List>
            )}

            <Box sx={{ position: 'fixed', bottom: 70, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                <Button variant="contained" color="primary"   onClick={() => handleCreateCase()}>
                    Создать обращение
                </Button>
            </Box>
        </div>
    );
};

export default CasesListPage;
