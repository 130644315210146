import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'reflect-metadata';
import Login from './screens/auth/Login';
import PasswordRecovery from './screens/auth/PasswordRecovery';
import StudentList from './screens/student/StudentList';
import StudentDetails from './screens/student/StudentDetails';
import PaymentsPage from "./screens/payment/PaymentsPage";
import CreatePaymentPage from "./screens/payment/CreatePaymentPage";
import CasesListPage from "./screens/cases/CasesListPage";
import CreateCasePage from "./screens/cases/CreateCasePage";
import CaseDetailPage from "./screens/cases/CaseDetailPage";
import ChangePasswordPage from "./screens/auth/ChangePasswordPage";
import BaseLayout from "./base/views/BaseLayout";
import AgreemantPage from "./screens/student/AgreemantPage";
import { CircularProgress } from "@mui/material";
import { UserStore } from "./screens/auth/UserStore";
import ProfilePage from "./screens/profile/ProfilePage";
import InstallPwaDialog from "./base/views/InstallPwaDialog";
import IosPwaPrompt from "./base/views/IosPwaPromt";
import AdminRouter from "./base/views/AdminRouter";
import ClientRouter from "./base/views/ClientRouter";
import {ThemeProvider} from "@mui/material/styles";
import themeAtesh from "./base/views/Theme";


const App: React.FC = () => {


    const screenSet: string | undefined = process.env.REACT_APP_SCREEN_SET;

    switch (screenSet) {
        case 'client':
            return  <ClientRouter />;
        case 'admin':
            return <AdminRouter />;
        default:
            return <h1>Screen set is not specified correctly.</h1>;
    }
};

export default App;

/*
export default App;

const App: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('home');

  return (

      <Router>
          <Routes>
           /*   <Route path="/" element={<Login />} index /> {/* Автоматический редирект на страницу логина *///}
              /*  <Route path="/password-recovery" element={<PasswordRecovery />} />
                <Route path="/students" element={<StudentList />} />
                <Route path="/cases" element={<CasesListPage />} />
                <Route path="/changePassword" element={<ChangePasswordPage />} />
                <Route path="/cases/:id" element={<CaseDetailPage />} />
                <Route path="/createcase" element={<CreateCasePage />} />
                <Route path="/agreement" element={<AgreemantPage />} />
                <Route path="/students/:studentId" element={<StudentDetails />} />
                <Route path="/students/:studentId/payments" element={<PaymentsPage />} />
                <Route path="/students/:studentId/createpayment" element={<CreatePaymentPage />} />
            </Routes>
            <BottomMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </Router>
    );
};

export default App;

               */
