import BottomMenu from "./BottomMenu";
import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import themeAtesh from "./Theme";
import {ThemeProvider} from "@mui/styles";
import theme from "./Theme";

interface IBaseLayoutProps {

}

const BaseLayout: React.FC<IBaseLayoutProps> = () => {

    const [selectedTab, setSelectedTab] = useState<string>('home');
    return (
    <>
                <Outlet />
                <BottomMenu />
    </>
    );
};

export default BaseLayout;
