// StudentList.tsx

import {Student} from "../../components/models/Student";
// StudentList.tsx
import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Button, Card, CardContent, Typography,Box } from '@mui/material';
import ApiService from '../../base/api/ApiService';
import CommonHeader from "../../base/views/CommonHeader";


const StudentList: React.FC = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState<Student[]>([]);

    useEffect(() => {
        // Загрузка списка студентов при монтировании компонента
        loadStudentList();
    }, []);

    const loadStudentList = async () => {
        try {
            // Загрузка списка студентов с использованием нового ApiService
            const response = await ApiService.getStudents();
            setStudents(response || []);



            console.log(students);
        } catch (error) {
            console.error('Error loading student list:', error);
        }
    };

    const viewStudentDetails = (studentId: string|null) => {
        // Переход к странице с подробной информацией о студенте
        navigate(`/students/${studentId}`);
    };

    return (
        <div>
            <CommonHeader title="Список учеников" showBackButton={false} />
            {students.map((student) => (
                <Card key={student.id} style={{ marginBottom: '10px', margin:"5px" }}>
                    <CardContent>
                        <Typography variant="h6">{student.name}</Typography>
                        <Box mt={1} display="flex" flexDirection="row">
                            <Typography color="textSecondary">Группа:</Typography>

                            <Typography color="textSecondary" >{student.groupName}</Typography>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => viewStudentDetails(student?.id)}
                            style={{ marginTop: '10px' }}
                        >
                            Подробнее
                        </Button>
                    </CardContent>
                </Card>

            ))}
        </div>
    );
};

export default StudentList;

