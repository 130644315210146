import { Serializable, jsonProperty } from 'ts-serializable';

import type { Nullable } from './BaseTypes';



export class task extends  Serializable{
    @jsonProperty(String, null) read:Nullable<string>= null;
    @jsonProperty(String, null) stream:Nullable<string>= null;
    @jsonProperty(String, null) edit:Nullable<string>= null;
    @jsonProperty(String, null) delete:Nullable<string>= null;
    @jsonProperty(String, null) create:Nullable<string>= null;
}
export class table extends  Serializable
{
    @jsonProperty(task, null) Task:Nullable<task>= null;
    @jsonProperty(task, null) Payment:Nullable<task>= null;

}

export  class  acl extends  Serializable{
    @jsonProperty(table, null) table:Nullable<table>= null;

}
export class User extends Serializable {
    @jsonProperty(String, null) id: Nullable<string> = null;
    @jsonProperty(String, null) name: Nullable<string> = null;
    @jsonProperty(String, null) token: Nullable<string> = null;
    @jsonProperty(Boolean, null) deleted: Nullable<boolean> = null;
    @jsonProperty(String, null) userName: Nullable<string> = null;
    @jsonProperty(String, null) type: Nullable<string> = null;
    @jsonProperty(String, null) authMethod: Nullable<string> = null;
    @jsonProperty(String, null) apiKey: Nullable<string> = null;
    @jsonProperty(String, null) salutationName: Nullable<string> = null;
    @jsonProperty(String, null) firstName: Nullable<string> = null;
    @jsonProperty(String, null) lastName: Nullable<string> = null;
    @jsonProperty(Boolean, null) isActive: Nullable<boolean> = null;
    @jsonProperty(String, null) title: Nullable<string> = null;
    @jsonProperty(String, null) emailAddress: Nullable<string> = null;
    @jsonProperty(String, null) phoneNumber: Nullable<string> = null;
    @jsonProperty(String, null) rolesNames: Nullable<Object[]> = null;
    @jsonProperty(String, null) ipAddress: Nullable<string> = null;
    @jsonProperty(String, null) gender: Nullable<string> = null;
    @jsonProperty(String, null) createdAt: Nullable<string> = null;
    @jsonProperty(String, null) modifiedAt: Nullable<string> = null;
    @jsonProperty(String, null) auth2FA: Nullable<string> = null;
    @jsonProperty(String, null) middleName: Nullable<string> = null;
    @jsonProperty(String, null) emailAddressIsOptedOut: Nullable<string> = null;
    @jsonProperty(Boolean, null) phoneNumberIsOptedOut: Nullable<boolean> = null;
    @jsonProperty(Boolean) isAgreement: Nullable<boolean> = false;
    @jsonProperty(String, null) defaultTeamId: Nullable<string> = null;
    @jsonProperty(String, null) defaultTeamName: Nullable<string> = null;
    @jsonProperty([String], null) teamsIds: Nullable<string[]> = null;
    @jsonProperty(Object, null) teamsNames: Nullable<Object> = null; //TODO: поменять, когда будет известно содержимое
    @jsonProperty(String, null) contactId: Nullable<string> = null;
    @jsonProperty(String, null) contactName: Nullable<string> = null;
    @jsonProperty(String, null) avatarId: Nullable<string> = null;
    @jsonProperty(String, null) avatarName: Nullable<string> = null;
    @jsonProperty(String, null) createdById: Nullable<string> = null;
    @jsonProperty(String, null) createdByName: Nullable<string> = null;
    @jsonProperty(String, null) dashboardTemplateId: Nullable<string> = null;
    @jsonProperty(String, null) dashboardTemplateName: Nullable<string> = null;
    @jsonProperty([Object], null) emailAddressList: Nullable<Object[]> = null; //TODO: поменять, когда будет известно содержимое
    @jsonProperty([Object], null) userEmailAddressList: Nullable<Object[]> = null; //TODO: поменять, когда будет известно содержимое

    @jsonProperty(acl, null)Acl:Nullable<acl>=null;
}

export class userMain extends Serializable{
    @jsonProperty(User, null) user:Nullable<User>= null;
    @jsonProperty(acl, null) acl:Nullable<acl>= null;
    @jsonProperty(String, null) token: Nullable<string> = null;


}



