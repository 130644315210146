import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const BottomMenu = () => {
    const navigate = useNavigate();
    // Допустим, что вы храните текущий выбранный путь в URL,
    // и используете его для определения активной вкладки
    const currentPath = window.location.pathname;

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(`/${newValue}`); // Используйте navigate для изменения URL
    };

    return (
        <Box sx={{ pb: 7 }}>
            <BottomNavigation value={currentPath.replace('/', '')} onChange={handleChange} sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
                <BottomNavigationAction label="Главная" value="students" icon={<HomeIcon />} />
                <BottomNavigationAction label="Платежи" value="payments" icon={<PaymentIcon />} />
                <BottomNavigationAction label="Обращения" value="cases" icon={<ContactSupportIcon />} />
                <BottomNavigationAction label="Профиль" value="profile" icon={<AccountCircleIcon />} />
            </BottomNavigation>
        </Box>
    );
};

export default BottomMenu;
