import React from 'react';
import CommonHeader from "../../base/views/CommonHeader";

const AboutPage: React.FC = () => {
    const appVersion = '1.0.19'; // Ваша версия приложения

    return (
        <div>
            <CommonHeader title={"О приложении"} showBackButton={true}/>
            <div style={{margin:'8px'}}>
            <p>Здесь вы можете легко просматривать расписание занятий, отслеживать историю посещений и проводить оплаты в несколько кликов. Всё это и многое другое доступно в одном удобном месте, чтобы обеспечить вам максимальное удобство и контроль над вашими активностями. Зайдите в ваш личный кабинет сегодня и начните пользоваться всеми его преимуществами!</p>
                <p></p>
                <p>Версия приложения: {appVersion}</p>
            </div>
        </div>
    );
};

export default AboutPage;
