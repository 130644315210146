// ProfilePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import CommonHeader from "../../base/views/CommonHeader";
import ApiService from "../../base/api/ApiService";

const listItemTextStyle = {
    fontSize: '1.4rem',
    color: "black"
};
const handleLogout = () => {
    // Ваша логика для выхода из системы
    // Например, очистка данных пользователя и перенаправление на страницу авторизации
    ApiService.logout();
    window.location.reload();
};

const ProfilePage: React.FC = () => {
    return (
        <>
        <CommonHeader title="Профиль"/>
        <div style={{ padding: '20px' }}>
            <List>
                <ListItem component={Link} to="/changePassword">
                    <ListItemText primaryTypographyProps={{ style: listItemTextStyle }} primary="Сменить пароль" />
                </ListItem>

                <Divider />
                <ListItem component={Link} to="/agreement">
                    <ListItemText primaryTypographyProps={{ style: listItemTextStyle }} primary="Пользовательское соглашение" />
                </ListItem>
                <Divider />
                <ListItem component={Link} to="/aboutApp">
                    <ListItemText primaryTypographyProps={{ style: listItemTextStyle }} primary="О приложении" />
                </ListItem>
                <Divider />
                <ListItem  onClick={handleLogout}>
                    <ListItemText primaryTypographyProps={{ style: listItemTextStyle }} primary="Выход" />
                </ListItem>
            </List>
        </div>
      </>
    );
};

export default ProfilePage;
