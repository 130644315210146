// SomeOtherComponent.tsx

import React, {useEffect, useState} from 'react';
import StudentDetailsComponent from './StudentDetailsComponent';
import { useNavigate,useParams } from 'react-router-dom';
import {Box, Button, Typography} from "@mui/material";
import AttendancePageComponent from "./AttendancePageComponent";
import CommonHeader from "../../base/views/CommonHeader";
import SchedulePageComponent from "./SchedulePageComponent";
import NearestLessonComponent from "../../components/NearestLessonComponent";
import ApiService from "../../base/api/ApiService";
import {Attendance} from "../../components/models/Attendance";


const StudentDetails: React.FC = () => {
    //const studentId = '123'; // Подставьте фактический ID ученика
    const navigate = useNavigate();
    const  {studentId } = useParams<{ studentId: string }>();
    const [visits, setVisits] = useState<Attendance[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchVisits = async () => {
            try {
                const today = new Date(); // Получаем текущую дату
                today.setDate(today.getDate() - 4); // Отнимаем 4 дня
                const formattedDate = today.toISOString().slice(0, 10);
                const response = await ApiService.getAttendance(studentId as string, formattedDate);
                setVisits(response);
                console.log(response);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка получения списка посещений:', error);
                setLoading(false);
            }
        };
        fetchVisits();
    }, [studentId]);

    const handlePayment = (studentId: string|null) => {
        navigate(`/students/${studentId}/payments`);    // Ваш код для обработки оплаты или перенаправления на страницу оплаты
    };

    // Предположим, что здесь есть другие ваши компоненты или JSX
    return (
        <div>
            <CommonHeader title="Подробно" showBackButton={true} />
            <div   style={{ margin: '5px' }}>
                <StudentDetailsComponent studentId={studentId as string } />
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handlePayment(studentId as string)}
                style={{ marginTop: '10px', width:'70%', left: '50%', transform: 'translateX(-50%)'  }}
            >
                Оплаты
            </Button>
            <SchedulePageComponent studentId={studentId as string }/>
            <AttendancePageComponent studentId={studentId as string } attendance={visits}/>

        </div>
    );


};



export default StudentDetails;