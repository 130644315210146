import moment from "moment";

class Helper
{
   static  formatTimeWithTimeZone = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const timeZoneOffset = (date.getTimezoneOffset() / 60)*(-1);
    const formattedTime = new Date(date.getTime() + timeZoneOffset * 3600 * 1000).toLocaleString();
   // return formattedTime;
   return  moment.utc(dateTimeString).local().format("DD.MM.YYYY HH:mm")
};
}
export default Helper;
