// components/StudentDetails.tsx

import React, { useEffect, useState } from 'react';
import ApiService from '../../base/api/ApiService';
import { Student } from '../../components/models/Student';
import CommonHeader from "../../base/views/CommonHeader";
import './StudentDetailsComponent.css';


interface StudentDetailsProps {
    // studentId теперь является частью пропсов
    studentId: string;
}



const StudentDetailsComponent: React.FC<StudentDetailsProps> = ({ studentId }) => {
    const [studentDetails, setStudentDetails] = useState<Student | null>(null);
    const [base64Image, setBase64Image] = useState('');

    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const response = await ApiService.getStudentDetails(studentId);
                setStudentDetails(response);
            } catch (error) {
                console.error('Error fetching student details:', error);
            }
        };

        fetchStudentDetails();
    }, [studentId]);

    if (!studentDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mainDiv">
            <div>
                <h2>{studentDetails.name}</h2>
                <p>Группа: {studentDetails.groupName}</p>
                <div>
                    <p>Тариф: {studentDetails.monthlyFee?.toString()} руб/месяц</p>
                </div>
                {/* Добавьте другие поля */}
            </div>
        </div>
    );
};


const handleImageClick = () => {
    // Здесь вы можете реализовать логику для увеличения изображения
    // Например, открытие модального окна с увеличенным изображением
};


export default StudentDetailsComponent;
