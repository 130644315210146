import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/IosShare'; // Предполагается, что вы используете MUI для иконок
import AddHomeIcon from '@mui/icons-material/AddBoxOutlined'; // Предполагается, что вы используете MUI для иконок

const IosPwaPrompt: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const isIos = (): boolean => /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
        const isInStandaloneMode = (): boolean => ('standalone' in window.navigator) && (window.navigator as any).standalone;

        const showPrompt = (): boolean => {
            const lastPromptDate = localStorage.getItem('lastIosPrompt');
            const today = new Date().toDateString();

            if (lastPromptDate === today) {
                return false;
            }

            localStorage.setItem('lastIosPrompt', today);
            return true;
        };

        if (isIos() && !isInStandaloneMode() && showPrompt()) {
            setIsVisible(true);
        }
    }, []);

    const hidePrompt = (): void => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Box sx={{ padding: 2,margin:'4px',  backgroundColor: '#f0f0f5', position: 'fixed', bottom: 10, left: 10, right: 10, borderRadius: 2, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ShareIcon /> {/* Иконка Поделиться */}
                Хотите быстро получить доступ к приложению? Добавь приложение на главный экран!
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AddHomeIcon /> {/* Иконка Добавить на главный экран */}
                Нажмите Поделиться в меню браузера, а затем На экран Домой.
            </Typography>
            <Button onClick={hidePrompt} sx={{ marginTop: 2 }}>
                Закрыть
            </Button>
        </Box>
    );
};

export default IosPwaPrompt;
