// src/components/PasswordRecovery.tsx
import React, { useState } from 'react';
import axios from 'axios';

const PasswordRecovery: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const handlePasswordRecovery = async () => {
        try {
            await axios.post('http://crm.ateshstudio.ru/api/v1/portal-access/651a7418c3e4d46f2/User/passwordChangeRequest', {
                phoneNumber,
                email,
            });

            // Добавьте обработку успешной отправки запроса на восстановление пароля
        } catch (error) {
            console.error('Ошибка восстановления пароля', error);
        }
    };

    return (
        <div>
            <h2>Восстановление доступа</h2>
            <input type="text" placeholder="Номер телефона" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button onClick={handlePasswordRecovery}>Восстановить пароль</button>
        </div>
    );
};

export default PasswordRecovery;
