import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Container,
    CssBaseline,
    Snackbar,
    IconButton,
    InputAdornment,
    LinearProgress
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ApiService from "../../base/api/ApiService";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 1,

    },
    form: {
        width: '100%',
        marginTop: 1,
    },
    submit: {

    },
}));

const Login: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Состояние для отслеживания загрузки


    useEffect(() => {
        // Загрузка списка студентов при монтировании компонента
      setUsername(ApiService.getUsername()??"");
    }, []);
    const handleLogin = async () => {
        setLoading(true); // Устанавливаем состояние загрузки в true перед отправкой запроса
        try {

            const response = await ApiService.login(username.replace('+',''), password);

            const token = response.token;
            console.log("response.isAgreement");

            console.log(response.isAgreement);
            if(!response.isAgreement) {
                navigate('/agreement');
                return;
            }
            navigate('/students');
            window.location.reload();

        } catch (error) {
            console.error('Ошибка авторизации:', error);
            setError('Неверный логин или пароль');
            setSnackbarOpen(true);
        } finally {
            setLoading(false); // Устанавливаем состояние загрузки обратно в false после завершения запроса
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Вход
                </Typography>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{style: {fontSize: 20}}}
                        id="username"
                        label="Имя пользователя / Телефон"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => { setUsername(e.target.value); setError(''); }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{style: {fontSize: 20}}}
                        name="password"
                        label="Пароль"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); setError(''); }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {loading && <LinearProgress />} {/* Индикатор загрузки */}

                    <Button
                        style={{ marginTop: '10px' }}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogin}
                    >
                        Войти
                    </Button>
                </form>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Неверный логин или пароль"
            />
        </Container>
    );
};

export default Login;
