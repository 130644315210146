import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Select, Typography, SelectChangeEvent } from '@mui/material';
import { Lesson } from "../../components/models/Lesson";  // Замените на путь к вашим моделям
import { Group } from "../../components/models/Group";  // Замените на путь к вашим моделям
import moment from 'moment';
import ApiService from "../../base/api/ApiService";
import 'moment/locale/ru';
import CommonHeader from "../../base/views/CommonHeader";
import CircularProgressBar from "../../base/views/CircularProgressBar"; // Импортируем русскую локализацию для moment

moment.locale('ru'); // Устанавливаем русскую локализацию для moment

const WeeklyReportPage: React.FC = () => {
    const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
    const [facilities, setFacilities] = useState<any[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [lessons, setLessons] = useState<Lesson[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [weekStartDate, setWeekStartDate] = useState<string>('');
    const [weekEndDate, setWeekEndDate] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                setIsLoading(true);
                const response = await ApiService.getFacilityes();
                setFacilities(response);
                const defaultFacility = localStorage.getItem('defaultFacility');
                if (defaultFacility) {
                    setSelectedFacility(defaultFacility);
                }
            } catch (error) {
                console.error('Ошибка при получении списка филиалов:', error);
            }  finally {
            setIsLoading(false);
        }


        };

        fetchFacilities();
    }, []);

    useEffect(() => {
        if (selectedFacility) {
            const fetchGroups = async () => {
                try {
                    setIsLoading(true);
                    const response = await ApiService.getGroups(selectedFacility);
                    setGroups(response);
                } catch (error) {
                    console.error('Ошибка при получении списка групп:', error);

            } finally {
                setIsLoading(false);
            }
            };

            fetchGroups();
        }
    }, [selectedFacility]);

    useEffect(() => {
        if (groups.length > 0) {
            const fetchLessons = async () => {
                try {
                    setIsLoading(true);
                    const startDate = getWeekStartDate(currentTab);
                    const endDate = getWeekEndDate(currentTab);
                    setWeekStartDate(startDate);
                    setWeekEndDate(endDate);
                    const response = await ApiService.getLessonsForWeek(groups.map(group => group.id!), startDate, endDate);
                    setLessons(response);
                } catch (error) {
                    console.error('Ошибка при получении занятий на неделю:', error);
                }
                finally {
                    setIsLoading(false);
                }
            };

            fetchLessons();
        }
    }, [groups, currentTab]);

    const getWeekStartDate = (tabIndex: number): string => {
        const currentDate = new Date();
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1 + (tabIndex * 7));
        return formatDate(startOfWeek);
    };

    const getWeekEndDate = (tabIndex: number): string => {
        const currentDate = new Date();
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(endOfWeek.getDate() + (7 - endOfWeek.getDay()) + (tabIndex * 7));
        return formatDate(endOfWeek);
    };

    const formatDate = (date: Date): string => {
        return moment(date).format('YYYY.MM.DD');
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    const handleFacilityChange = (event: SelectChangeEvent<string>) => {
        const facility = event.target.value as string;
        setLessons([] as Lesson[]);
        setSelectedFacility(facility);
        localStorage.setItem('defaultFacility', facility);
    };

    const handleLessonClick = (lessonId: string) => {
        navigate(`/lesson/${lessonId}`);
    };

    const groupedLessons = lessons.reduce((acc: any, lesson: Lesson) => {
        const day = moment(lesson.lessontime).format('dddd DD').charAt(0).toUpperCase() + moment(lesson.lessontime).format('dddd DD').slice(1);
        const salon = lesson.salonName || 'Без зала';
        if (!acc[day]) {
            acc[day] = {};
        }
        if (!acc[day][salon]) {
            acc[day][salon] = [];
        }
        acc[day][salon].push(lesson);
        return acc;
    }, {});

    // Сортировка дней недели в правильном порядке
    const sortedDays = Object.keys(groupedLessons).sort((a, b) => {
        const daysOrder = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
        const dayA = a.split(' ')[0];
        const dayB = b.split(' ')[0];
        return daysOrder.indexOf(dayA) - daysOrder.indexOf(dayB);
    });

    return (
        <div>
        <CommonHeader title="Расписание|План" showBackButton={true} />
       {isLoading && <CircularProgressBar />}
        <Box sx={{m:'4px'}}>
            <Box  sx={{mt:'6px', marginRight:'4px', marginLeft:'4px'}}>
            <Select value={selectedFacility || ''} onChange={handleFacilityChange} fullWidth>
                {facilities.map((facility) => (
                    <MenuItem key={facility.id} value={facility.id}>
                        {facility.name}
                    </MenuItem>
                ))}
            </Select>
            </Box>
            <Tabs value={currentTab} onChange={handleTabChange} aria-label="tabs">
                <Tab label="Текущая неделя" />
                <Tab label="Следующая неделя" />
            </Tabs>
            <TableContainer>
                <Table>
                    {sortedDays.map((day) => (
                        <React.Fragment key={day}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ backgroundColor: '#f0f0f0' }}>
                                        <Typography variant="h6">{day}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(groupedLessons[day]).map((salon) => (
                                    <React.Fragment key={salon}>
                                        <TableRow>
                                            <TableCell colSpan={2} style={{ backgroundColor: '#d0d0d0' }}>
                                                <Typography variant="subtitle1">{salon}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {groupedLessons[day][salon].map((lesson: Lesson) => (
                                            <TableRow key={lesson.id} onClick={() => handleLessonClick(lesson.id as string)} style={{ cursor: 'pointer' }}>
                                                <TableCell>{lesson.groupName}</TableCell>
                                                <TableCell>{moment.utc(lesson.lessontime).local().format('HH:mm')+'-'+moment.utc(lesson.endtime).local().format('HH:mm')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </React.Fragment>
                    ))}
                </Table>
            </TableContainer>
        </Box>
        </div>
    );
};

export default WeeklyReportPage;


