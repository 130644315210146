import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Collapse,
    Box,
    Typography
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import ApiService from "../../base/api/ApiService";
import {Schedule} from "../../components/models/Schedule";


interface ScheduleProps {
    studentId: string;
}

const SchedulePageComponent: React.FC<ScheduleProps> = ({ studentId }) => {
    const [schedule, setSchedule] = useState<Schedule[]>([]);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const response = await ApiService.getSchedule(studentId);
                const activeLessons = response.filter((lesson: Schedule) => lesson.isActive); // Фильтруем активные уроки
                setSchedule(activeLessons);
            } catch (error) {
                console.error('Ошибка получения расписания:', error);
            }
        };

        fetchSchedule();
    }, [studentId]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const cellStyles = {
        fontSize: '1.15rem',
    };
    const cellStylesSubtitle = {
        fontSize: '0.9rem',
    };

    return (
        <Box mt={2}>
            <IconButton onClick={toggleExpand}>
                <span>Расписание</span>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Collapse in={expanded}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>

                        </TableHead>
                        <TableBody>
                            {schedule.map((lesson) => (
                                <TableRow key={lesson.id}>

                                    <TableCell><Typography variant="body1" sx={cellStyles}>{lesson.dayOfWeekTranslate}</Typography></TableCell>
                                    <TableCell>
                                        <Box>
                                        <Typography variant="body1" sx={cellStyles}>{lesson.time}</Typography>
                                         <Typography variant="body1" sx={cellStylesSubtitle}>{lesson.duration} мин.</Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </Box>
    );
};

export default SchedulePageComponent;
