import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import {Payment} from "../../components/models/Payment";

interface PaymentDialogProps {
    payment: Payment|null;
    onClose: () => void;
    onYes: () => void;
}

const RepeatedPaymentDialog: React.FC<PaymentDialogProps> = ({ payment, onClose, onYes }) => {
    return (
        <div>
        <Dialog open={true} onClose={onClose} >
            <DialogTitle>Уведомление об оплате</DialogTitle>
            <DialogContent>
                <>
                   Выбранный период за {payment?.studentName} в размере {payment?.summ} руб.  уже оплачен. Повторить оплату?
                </>
                </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Закрыть
                </Button>
                <Button onClick={onYes} color="primary">
                    Продолжить
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default RepeatedPaymentDialog;
