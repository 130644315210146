import React from 'react';
import AgreementPageComponent from "./AgreemantPageComponent";
import PDFViwer from "../../base/views/PDFViwer";
import {PDFViewer} from "@react-pdf/renderer";
import CommonHeader from "../../base/views/CommonHeader";

const AgreementPage: React.FC = () => {
    const agreementUrl = 'https://crm.ateshstudio.ru/?entryPoint=FileSharing&id=660553c9edafaec4a&token=a4dd3b814acd67e44350e9a7dea88d1adc07';
    const agreementUrlPdf='https://crm.ateshstudio.ru/?entryPoint=FileSharing&id=66055d0a9cb8ed0d8&token=879ab6d1a420ccee6454fcd6789d24022b58';
    return (
        <div>
            {/* Другие компоненты вашего приложения */}
            {/* <CommonHeader title="Соглашение"/>
            {/* <PDFViwer pdfUrl={agreementUrl}/>*/}
            {/* <object width="100%" height="400" data={agreementUrl} type="application/pdf">   </object>*/}
            <AgreementPageComponent agreementUrl={agreementUrl} agreementUrlPdf={agreementUrlPdf}/>
        </div>
    );
};

export default AgreementPage;
