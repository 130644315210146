import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Box, Chip } from '@mui/material';
import ApiService from "../../base/api/ApiService";
import {Case, CaseHelper} from "../../components/models/case";
import CommonHeader from "../../base/views/CommonHeader";
import {color} from "@mui/system";


const CaseDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [caseDetail, setCaseDetail] = useState<Case | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCaseDetail = async () => {
            try {
                const response = await ApiService.getCaseDetail(id as string);
                setCaseDetail(response);
            } catch (error) {
                console.error('Ошибка получения подробной информации обращения:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCaseDetail();
    }, [id]);

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'New':
                return '#f44336'; // Красный
            case 'In Progress':
                return '#ff9800'; // Оранжевый
            case 'Completed':
                return '#4caf50'; // Зеленый
            default:
                return '#e0e0e0'; // Серый (по умолчанию)
        }
    };



    const getGradientBackground = () => {
        return `linear-gradient(to right, #FAAA00, #FB1D00)`;
    };

    return (
        <div>
            <CommonHeader title="Обращение" showBackButton={true} />
            <Box margin={1}>
                {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </div>
                ) :
                    (
                    <>
                        <Typography variant="h5">{CaseHelper.getTypeName(caseDetail?.type ?? "")}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1">Дата: {caseDetail?.createdAt}</Typography>
                            <Chip label={caseDetail?.status} style={{ backgroundColor: getStatusColor(caseDetail?.status || '') }} sx={{ marginLeft: 'auto', color: 'white' }} />
                        </Box>
                        <Typography variant="h6">Тема: {caseDetail?.subject}</Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ padding:1.2, width:'80%', border:1, borderRadius:3}}>
                                <Typography variant="h6">Вопрос:</Typography>
                                <Typography variant="body1">{caseDetail?.description}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
                            <Box sx={{ padding:1.2, width:'80%', border:1, borderRadius:3}}>
                                <Typography variant="h6">Ответ:</Typography>
                                <Typography variant="body1">{caseDetail?.ansver}</Typography>
                        </Box>
                       </Box>
                    </>
                )}
            </Box>
        </div>
    );
};

export default CaseDetailPage;
