import {User} from "../../components/models/User";
import ApiService from "../../base/api/ApiService";


export class UserStore {
    loading = false;
    checkAuthLoading = true;
    isAuth = false;
    isAuthError = false;

    userInfo: User | null = null;


    constructor() {

    }

    initApp = async () => {
        this.checkAuth();
        if (this.isAuth) {
            await this.getUserInfo();
        }
    };

    getUserInfo = async () => {
        this.setLoading(true);
        ApiService.getUserData();
    };

    logout = () => {
        this.setIsAuth(false);
        ApiService.logout();
    };

    checkAuth = (): void => {
        this.setCheckAuthLoading(true);
        const access = ApiService.getUserData();
        if (access) {
            console.log(access);
            this.setIsAuth(true);
            this.setCheckAuthLoading(false);

            return;
        }

        this.setIsAuth(false);
        this.setCheckAuthLoading(false);
    };

    setLoading = (value: boolean): void => {
        this.loading = value;
    };



    setIsAuth = (value: boolean) => {
        this.isAuth = value;
    };

    setAuthError = (value: boolean) => {
        this.isAuthError = value;
    };

    setCheckAuthLoading = (value: boolean) => {
        this.checkAuthLoading = value;
    };

    setUserInfo = (info: User) => {
        this.userInfo = info;
    };


}
