import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    TextField,
    Button,
    Divider,
    Box,
    Chip,
    Checkbox, FormControlLabel
} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import { Attendance } from "../../components/models/Attendance";
import Helper from "../../components/Helper";
import CircularProgressBar from "../../base/views/CircularProgressBar";
import moment from "moment";

interface AttendancePageProps {
    studentId: string;
    attendance: Attendance[];
}

const AttendancePageComponent: React.FC<AttendancePageProps> = ({ studentId, attendance }) => {
    const [visits, setVisits] = useState<Attendance[]>(attendance);
    const [loading, setLoading] = useState<boolean>(false);
    const [expandedItemId, setExpandedItemId] = useState<string | null>(null);
    const [comment, setComment] = useState<string>('');
    const [nearestLessonId, setNearestLessonId] = useState<string | null>(null);

    useEffect(() => {
        const fetchVisits = async () => {
            try {
                const today = new Date();
                today.setDate(today.getDate() - 4);
                const formattedDate = today.toISOString().slice(0, 10);
                const response = await ApiService.getAttendance(studentId, formattedDate);

                setVisits(response);
                setLoading(false);
                findNearestLesson(response);
            } catch (error) {
                console.error('Ошибка получения списка посещений:', error);
                setLoading(false);
            }
        };

        fetchVisits();
    }, [studentId]);

    const findNearestLesson = (lessons: Attendance[]) => {
        const now = moment();
        let nearestLesson: Attendance | null = null;

        for (let lesson of lessons) {
            const lessonTime = moment.utc(lesson.lesontime).local();
            if (lessonTime.isSameOrAfter(now)) {
                if (!nearestLesson || lessonTime.isBefore(moment.utc(nearestLesson.lesontime).local())) {
                    nearestLesson = lesson;
                }
            }
        }

        if (nearestLesson) {
            setNearestLessonId(nearestLesson.id as string);
            // Переместим ближайшее занятие на первое место в списке
            setVisits(prevVisits => {
                const filteredVisits = prevVisits.filter(visit => visit.id !== nearestLesson!.id);
                return [nearestLesson!, ...filteredVisits];
            });
        }
    };

    const handleListItemClick = (visitId: string | null) => {
        if (visitId === expandedItemId) {
            setExpandedItemId(null);
        } else {
            setExpandedItemId(visitId);
        }
    };

    const handleCommentSubmit = async (visit: Attendance) => {
        if (!moment.utc(visit.lesontime).local().isBefore(moment())) {
            const attendance = await ApiService.postAttendanceComment(visit.studentId as string, visit.commentClient as string, visit.id as string, visit.isVisit as boolean);
            setComment('');
            setExpandedItemId(null);
        }
    };

    const getStatusColor = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return '#e0e0e0';
        } else {
            return visit.isVisit ? '#4caf50' : '#f44336';
        }
    };

    const setIsVisit = (visit: Attendance, isVisit: boolean) => {
        setVisits(prevVisits => {
            const updatedVisits = prevVisits.map(prevVisit => {
                if (prevVisit.id === visit.id) {
                    return { ...prevVisit, isVisit };
                } else {
                    return prevVisit;
                }
            });
            return updatedVisits;
        });
    };

    const getStatusLabel = (visit: Attendance) => {
        const currentTime = new Date().getTime();
        const visitTime = new Date(visit.lesontime as string).getTime();

        if (visitTime > currentTime) {
            return 'Расписание';
        } else {
            return visit.isVisit ? 'Присутствие' : 'Отсутствие';
        }
    };

    return (
        <div>
            {loading && <CircularProgressBar />}
            {!loading && (
                <List>
                    {visits.map((visit) => (
                        <div key={visit.id}>
                            <ListItem
                                button
                                onClick={() => handleListItemClick(visit.id)}
                                sx={{
                                    border: visit.id === nearestLessonId ? '2px solid green' : 'none',
                                    borderRadius: '8px',
                                    mb: 1,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <div>
                                            {`Дата и время: ${Helper.formatTimeWithTimeZone(visit.lesontime as string)}`}
                                            {visit.id === nearestLessonId && (
                                                <div style={{ color: 'green' }}>
                                                    Ближайшее занятие
                                                </div>
                                            )}
                                        </div>
                                    }
                                    secondary={
                                        <Chip
                                            label={getStatusLabel(visit)}
                                            style={{ backgroundColor: getStatusColor(visit), color: 'white' }}
                                        />
                                    }
                                />
                            </ListItem>
                            <Collapse in={visit.id === expandedItemId} timeout="auto" unmountOnExit>
                                <Divider />
                                <Box p={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={visit.isVisit as boolean} onChange={(e) => setIsVisit(visit, e.target.checked)} />}
                                        label="Будет на занятии"
                                    />
                                    <TextField
                                        label="Комментарий"
                                        variant="outlined"
                                        fullWidth
                                        value={visit.commentClient}
                                        onChange={(e) => setComment(visit.commentClient = e.target.value)}
                                        multiline
                                        rows={4}
                                        sx={{ marginBottom: '3px' }}
                                    />
                                    <Button variant="contained" color="primary" onClick={() => handleCommentSubmit(visit)}>
                                        Отправить
                                    </Button>
                                </Box>
                            </Collapse>
                            <Divider />
                        </div>
                    ))}
                </List>
            )}
        </div>
    );
};

export default AttendancePageComponent;
