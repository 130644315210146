import React from 'react';
import { Button, Fab, IconButton, Box } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {PdfJs} from "@react-pdf-viewer/core";
import GetAppIcon from '@mui/icons-material/GetApp';

import axios from 'axios';
import CommonHeader from "../../base/views/CommonHeader";
import ApiService from "../../base/api/ApiService";

interface AgreementPropsComponent {
    agreementUrl: string;
    agreementUrlPdf: string;
}

const AgreementPageComponent: React.FC<AgreementPropsComponent> = ({ agreementUrl,agreementUrlPdf }) => {
    const navigate = useNavigate();

    const handleAccept =async () => {
       await ApiService.postAgreement();
        navigate('/students');
        window.location.reload();
    };

    return (
        <div style={{ position: 'relative' }}>
            <CommonHeader title="Пользовательское соглашение" showBackButton={false} />
           <Box
               style={{
                   width: '100%',
                   height: '100%'}}
           >
            <iframe src={agreementUrl}    style={{
                width: '100%',
                height: '100vh', // 56px - это высота заголовка
                border: 'none',
            }} />
           </Box>
            <Fab
                variant="extended"
                color="primary"
                style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}
                onClick={handleAccept}
            >
                Я принимаю условия
            </Fab>
            <Box
                style={{ position: 'absolute', bottom: '20px', right: '20px', zIndex: 1000 }}
                sx={{ bgcolor: 'primary.main', borderRadius: '40%' }}
            >
                <IconButton
                    onClick={() => window.open(agreementUrlPdf, '_blank')}
                    sx={{ color: 'white' }}
                >
                    <GetAppIcon />
                </IconButton>
            </Box>
        </div>
    );
};

export default AgreementPageComponent;
