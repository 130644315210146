import React, { useState } from 'react';
import {Button, TextField, Typography, Box, CircularProgress, Container} from '@mui/material';
import ApiService from "../../base/api/ApiService";
import CommonHeader from "../../base/views/CommonHeader";


const ChangePasswordPage: React.FC = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(event.target.value);
    };

    const handleNewPassword1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword1(event.target.value);
        setPasswordsMatch(event.target.value === newPassword2 && event.target.value.length >= 9);
    };

    const handleNewPassword2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword2(event.target.value);
        setPasswordsMatch(event.target.value === newPassword1 && event.target.value.length >= 9);
    };

    const handleSubmit = async () => {
        setLoading(true);
        // Simulating API call
        try {
          const respons = await ApiService.changePassword(oldPassword,newPassword1,newPassword2);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setMessage('Пароль успешно изменен.');
        } catch (error) {
            setMessage('Ошибка при изменении пароля. Попробуйте снова.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
        <CommonHeader title="Cмена пароля" showBackButton={true} />

        <Box>
            <Box sx={{ maxWidth: 400, p: 2 }}>
                <TextField
                    fullWidth
                    type="password"
                    label="Старый пароль"
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    type="password"
                    label="Новый пароль"
                    value={newPassword1}
                    onChange={handleNewPassword1Change}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    type="password"
                    label="Повторите новый пароль"
                    value={newPassword2}
                    onChange={handleNewPassword2Change}
                    margin="normal"
                    variant="outlined"
                    error={!passwordsMatch}
                    helperText={!passwordsMatch && 'Пароли не совпадают или не соответствуют минимальной длине (минимум 9 символов)'}
                />
                {message && (
                    <Typography variant="body1" align="center" color={message.includes('Ошибка') ? 'error' : 'success'}>
                        {message}
                    </Typography>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!passwordsMatch || loading}
                    sx={{ mt: 2 }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Сменить пароль'}
                </Button>
            </Box>
        </Box>
        </div>
    );
};

export default ChangePasswordPage;
